<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto" id="calendar-container">
      <FullCalendar id="calendar" ref="calendar" :options="calendarOptions"  />
    </div>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";

import NotifyMixin from "@/mixins/NotifyMixin";
import FullCalendar /*, {Calendar}*/ from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

export default {
  mixins: [NotifyMixin, AuthMixin, ModalNavigation],
  components: {
    Loader,
    FullCalendar,
  },
  data() {
    return {
      loading: false,
      timeSlots: null,
      events: [],

      // Slots meta information
      slotStyles: {
        empty: {
          name: '',
          status: 'empty',
          bgColor: '#F1F6FB',
          textColor: '#333333',
        },
        unclaimed: {
          name: 'Unclaimed',
          status: 'unclaimed',
          bgColor: '#e69138',
          textColor: '#FFFFFF',
        },
        claimed: {
          name: 'Claimed',
          status: 'claimed',
          bgColor: '#6aa84f',
          textColor: '#FFFFFF',
        },
      },

      // Initial calendar options.
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "timeGridDay",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay",
        },
        expandRows: true,
        height: '100%',        
        slotMinTime: '08:00',
        slotMaxTime: '20:00',
        contentHeight : 'auto',
        eventClick: this.handleEventClick,
        events: this.eventsFetch
        
      },
    };
  },

  computed: {
  },

  methods: {
    resize() {
      let calendarApi = this.$refs.calendar.getApi()
      calendarApi.updateSize()
    },
    // This function will be called everytime the calendar is updated
    async eventsFetch(fetchInfo, successCallback/*, failureCallback*/){ 

      // Gets the events from the API
      let events = await this.getEventFromAPI(fetchInfo);

      // Calls the success function with the array of events
      successCallback(events);
    },

    handleEventClick: function (info) {
      // Gets the slot status in order to decide what screen to show
      let slotStatus = info.event.extendedProps.status;
      let appoinmentId = info.event.extendedProps.appointmentId;
      let slotId = info.event.extendedProps.slotId;

      switch (slotStatus) {
        case "empty":
          this.go("calendar_events.create");
          break;
        case "unclaimed":
          this.go("calendar_events.detail", { appointment_id: appoinmentId, slot_id: slotId });
          break;
        case "claimed":
          this.go("calendar_events.detail", { appointment_id: appoinmentId, slot_id: slotId });
          break;
      }
    },

    go(routeName, params) {
      this.$router.push({ name: routeName, params });
    },

    // Returns the events formatted for the calendar
    getEvents(timeSlots) {

     let events = [];
      
      // Loops over all the timeslots
      timeSlots.forEach((slot) => {
        
        // Splits the time window
        let timeWindow = slot.time_window.replace(/"|\(|\]/g, "").split(",");
        let timeWindowStart = timeWindow[0];
        let timeWindowEnd = timeWindow[1];
        const firstName = slot.appointment.guest.first_name;
        const lastName = slot.appointment.guest.last_name;
        const guestName = firstName + " " + lastName;
        const appointmentTypeName = slot.appointment.appointment_type_name
        let slotStyle = null;

        // Checks if the slot is empty
        if (slot.appointment.appointment_id === null) {
          slotStyle = "empty";
        }

        // if the appoinment is not empty, checks if it was not assigned to an user.
        else if (slot.appointment.user_id === null) {
          slotStyle = "unclaimed";
          this.slotStyles[slotStyle].name = 
          appointmentTypeName + " - " + guestName + "- unclaimed";
        }

        // If it was already assigned changes the status to claimed
        else {
          slotStyle = "claimed";
          this.slotStyles[slotStyle].name = 
          appointmentTypeName + " - " + guestName + "- claimed";
        }

        events.push({
          appointmentId: slot.appointment.appointment_id,
          slotId: slot.time_slot_id,
          start: timeWindowStart,
          status: this.slotStyles[slotStyle].status,
          end: timeWindowEnd,
          title: this.slotStyles[slotStyle].name,
          color: this.slotStyles[slotStyle].bgColor,
          textColor: this.slotStyles[slotStyle].textColor
        });
      });


      return events;
    },

    async getEventFromAPI(fetchInfo){

        this.loading = true;

        return await this.$calendarDataProvider
        .get('calendarGetCommunityAvailableTimeSlots', {
           "community_id": this.community.id,
           "start_date": fetchInfo.startStr,
           "end_date": fetchInfo.endStr,
        })
        .then((res) => {

          return this.getEvents(res)
        })
        .catch((err) => console.log(err.message))
        .finally(() => {
            this.loading = false;
        });         

    }
  },
  mounted() {
    setTimeout(this.resize, 500);
  },
  created() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    }
  },
};
</script>

<style scoped >

#calendar-container {

  :deep(.fc) {
    width: 100%;
    margin: 0 auto;
  }
  .active {
    @apply text-blue-500;
    border-left: 4px solid lightskyblue;
  }
  .active-text {
    @apply text-blue-500;
  }
  .tab {
    padding: 5px;
    cursor: pointer;
  }
  :deep(.fc .fc-col-header-cell-cushion) {
    display: inline-block;
    padding: 2px 4px;
  }

  :deep(.fc .fc-timegrid-slot){
    height:102px;
  }

  :deep(.fc-slats td) { 
    height: 10px !important; 
    font-size: 8px !important; 
  }

  :deep(.fc-agenda-slots td div) { height: 20px; }

  :deep(.fc-time-grid .fc-slats td) {
      height: 7.5em;
  }

  :deep(.fc-event) { /* allowed to be top-level */
      display: block;
      height: 50px !important;
  }
}

</style>